export const setSpinnerVisible = () => import('./page');

export const setMinicartVisible = () => import('./header');

export const resetCheckoutSteps = () => import('./checkout');
export const setCheckoutStep = () => import('./checkout');
export const setCheckoutShippingType = () => import('./checkout');

export const closeCheckoutMiniCart = () => import('./checkout');
export const openCheckoutMiniCart = () => import('./checkout');

export const setStoreList = () => import('./store');
export const setSelectedStore = () => import('./store');
export const getStoreStock = () => import('./store');
export const getStoreStockItem = () => import('./store');
export const setListStockInProgress = () => import('./store');

export const doSocialMidialLogin = () => import('./social-midia-login');
export const setSocialMidiaRegister = () => import('./social-midia-login');

export const addCompareProduct = () => import('./compare-products');
export const removeCompareProduct = () => import('./compare-products');
export const resetCompareProduct = () => import('./compare-products');

export const createAdvancedPromotion = () => import('./advanced-promotion');
