const ConfirmationItems = () => import('./items');
export {ConfirmationItems as _ConfirmationItems};

const ConfirmationSummary = () => import('./summary');
export {ConfirmationSummary as _ConfirmationSummary};

const ConfirmationMessage = () => import('./message');
export {ConfirmationMessage as _ConfirmationMessage};

const ConfirmationShippingDetails = () => import('./shipping-details');
export {ConfirmationShippingDetails as _ConfirmationShippingDetails};
