const GoBackLink = () => import('./GoBackLink');
const Logo = () => import('./header/Logo');
const WhatsappBar = () => import('./WhatsappBar');
const FeatureBannerCard = () => import('./FeatureBannerCard');
const ContactInfoCard = () => import('./ContactInfoCard');
const HomeProduct = () => import('./HomeProduct');
const Search = () => import('./header/Search');
const ProductList = () => import('./ProductList');
const Cart = () => import('./header/Cart');
const Categories = () => import('./header/Categories');
const StoreList = () => import('./header/StoreList');
const User = () => import('./header/User');
const Wishlist = () => import('./header/Wishlist');
const FooterSocial = () => import('./FooterSocial');
const FooterCopyright = () => import('./FooterCopyright');
const FooterNavigation = () => import('./FooterNavigation');
const HomeCarousel = () => import('./HomeCarousel');
const HeroBanner = () => import('./HeroBanner');
const SectionTitle = () => import('./SectionTitle');
const SimpleBanner = () => import('./SimpleBanner');
const CategoriesList = () => import('./CategoriesList');
const WelcomeBanner = () => import('./WelcomeBanner');
const ArticleItem = () => import('./ArticleItem');
const SafetyCard = () => import('./SafetyCard');
const FooterDivider = () => import('./FooterDivider');
const CategoriesListContext = () => import('./CategoriesListContext');
const DynamicProductList = () => import('./DynamicProductList');
const Breadcrumb = () => import('./Breadcrumb');
const ProfileContent = () => import('./ProfileContent');
const FaqContent = () => import('./FaqContent');
const HowToBuyContent = () => import('./HowToBuyContent');
const PrivacyOptionsContent = () => import('./PrivacyOptionsContent');
const SuggestionsForm = () => import('./SuggestionsForm');
const AboutUsContent = () => import('./AboutUsContent');
const RegulationsContent = () => import('./RegulationsContent');
const CatalogContent = () => import('./CatalogContent');
const SinsaDesignContent = () => import('./SinsaDesignContent');
const StoreListContent = () => import('./StoreListContent');
const ErrorContent = () => import('./ErrorContent');

const PageMetaConfiguration = () => import('./PageMetaConfiguration');
const WhatsappFloatingButton = () => import('./WhatsappFloatingButton');
const WebContent = () => import('./WebContent');
const AdvancedPromotions = () => import('./AdvancedPromotions');

export {GoBackLink as _GoBackLink};
export {Logo as _Logo};
export {WhatsappBar as _WhatsappBar};
export {FeatureBannerCard as _FeatureBannerCard};
export {ContactInfoCard as _ContactInfoCard};
export {HomeProduct as _HomeProduct};
export {Search as _Search};
export {HeroBanner as _HeroBanner};
export {ProductList as _ProductList};
export {Cart as _Cart};
export {Categories as _Categories};
export {StoreList as _StoreList};
export {User as _User};
export {Wishlist as _Wishlist};
export {FooterSocial as _FooterSocial};
export {FooterCopyright as _FooterCopyright};
export {FooterNavigation as _FooterNavigation};
export {HomeCarousel as _HomeCarousel};
export {SectionTitle as _SectionTitle};
export {SimpleBanner as _SimpleBanner};
export {CategoriesList as _CategoriesList};
export {WelcomeBanner as _WelcomeBanner};
export {ArticleItem as _ArticleItem};
export {SafetyCard as _SafetyCard};
export {FooterDivider as _FooterDivider};
export {CategoriesListContext as _CategoriesListContext};
export {DynamicProductList as _DynamicProductList};
export {Breadcrumb as _Breadcrumb};
export {ProfileContent as _ProfileContent};
export {FaqContent as _FaqContent};
export {HowToBuyContent as _HowToBuyContent};
export {PrivacyOptionsContent as _PrivacyOptionsContent};
export {SuggestionsForm as _SuggestionsForm};
export {AboutUsContent as _AboutUsContent};
export {RegulationsContent as _RegulationsContent};
export {CatalogContent as _CatalogContent};
export {SinsaDesignContent as _SinsaDesignContent};
export {StoreListContent as _StoreListContent};
export {ErrorContent as _ErrorContent};
export {PageMetaConfiguration as _PageMetaConfiguration};
export {WhatsappFloatingButton as _WhatsappFloatingButton};
export {WebContent as _WebContent};
export {AdvancedPromotions as _AdvancedPromotions};
