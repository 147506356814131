const ProductImage = () => import('./ProductImage');
const ProductDescription = () => import('./ProductDescription');
const ProductSpecs = () => import('./ProductSpecs');
const BuyTogether = () => import('./BuyTogether');
const RelatedProducts = () => import('./RelatedProducts');

export {ProductImage as _ProductImage};
export {ProductDescription as _ProductDescription};
export {ProductSpecs as _ProductSpecs};
export {BuyTogether as _BuyTogether};
export {RelatedProducts as _RelatedProducts};
